<template>
  <div
    class="d-flex flex-column align-content-center justify-center text-center grey--text text--darken-1"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      class="mx-auto mt-12 mb-4"
    />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'JLoading',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
}
</script>
